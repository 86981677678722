import React, { useState, useEffect } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import Fuse from 'fuse.js'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, List, ListSubheader } from '@material-ui/core'
import { Search, EnhancedTablePagination } from 'components'
import { useTranslation } from 'react-i18next'
import { IMG_TDC_LOGO_BUSSINESS } from 'constants/cdn'
import SignOut from 'views/Layout/components/SignOut'
import LanguageSwitcher from 'views/Layout/components/LanguageSwitcher'
import CustomerListItem from '../CustomerListItem'

const useStyles = makeStyles((theme) => ({
  list: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 600,
    marginTop: theme.spacing(2),
  },
  stickyHeader: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
  },
  ul: {
    padding: 0,
  },
  logo: {
    height: 40,
  },
  subText: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
}))

const searchOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['name', 'customer_id', 'union_name', 'union_id'],
}

const CustomerList = ({ history, customerList, authorizations }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [customers, setCustomers] = useState([])
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const chooseCustomer = (customerId) => {
    const pickedCustomer = authorizations.find(({ customer_id }) => customer_id === customerId)
    history.push(
      `/${customerId}/${
        pickedCustomer?.roles?.includes('oc_admin') ? 'operator-connect/numre' : 'dashboard'
      }`
    )
  }

  const filterCustomers = (searchTerm) => {
    setPage(0)
    const fuse = new Fuse(customers, searchOptions)
    const result = fuse.search(searchTerm)
    setFilteredCustomers(searchTerm.length > 0 ? result : customers)
    setActiveIndex(0)
  }

  const handleKeyPress = (key) => {
    switch (key) {
      case 'up':
        if (activeIndex > 0) setActiveIndex(activeIndex - 1)
        break
      case 'down':
        if (activeIndex < filteredCustomers.length - 1) setActiveIndex(activeIndex + 1)
        break
      case 'left':
        if (page > 0) setActiveIndex((page - 1) * rowsPerPage)
        break
      case 'right':
        if (
          page < Math.round(filteredCustomers.length / rowsPerPage && filteredCustomers.length > 9)
        )
          setActiveIndex((page + 1) * rowsPerPage)
        break
      case 'enter':
        chooseCustomer(filteredCustomers[activeIndex].customer_id)
        break
      default:
    }
  }

  useEffect(() => {
    setCustomers(customerList)
    setFilteredCustomers(customerList)
  }, [])

  useEffect(() => {
    if (activeIndex < page * rowsPerPage) {
      setPage(page - 1)
    } else if (activeIndex > page * rowsPerPage + rowsPerPage - 1) {
      setPage(page + 1)
    }
  }, [activeIndex])

  useEffect(() => {
    setActiveIndex(page * rowsPerPage)
  }, [rowsPerPage])

  return (
    <>
      <KeyboardEventHandler
        handleKeys={['up', 'down', 'left', 'right', 'enter']}
        handleFocusableElements
        onKeyEvent={(key) => handleKeyPress(key)}
      />
      <div className={classes.toolbar}>
        <img src={IMG_TDC_LOGO_BUSSINESS} alt="TDC Erhverv logo" className={classes.logo} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LanguageSwitcher />
          <SignOut />
        </div>
      </div>
      <Typography gutterBottom variant="h3">
        {t('Organization_customer_select_what_org')}
      </Typography>
      <Typography variant="body1" className={classes.subText}>
        {t('Organization_customer_select_access', { amount: customers.length })}
      </Typography>
      {customers.length > 10 && (
        <Search filter={filterCustomers} placeholder={t('Organization_customer_select_search')} />
      )}
      <List className={classes.list} subheader={<li />}>
        {filteredCustomers.length === 0 && (
          <Typography variant="body2">{t('Organization_customer_select_match')}</Typography>
        )}
        {filteredCustomers
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((customer, index) => (
            <li key={customer.customer_id}>
              <ul className={classes.ul}>
                {index === 0 && (
                  <ListSubheader className={classes.stickyHeader}>
                    {customer.union_name}
                  </ListSubheader>
                )}
                {index > 0 &&
                filteredCustomers[index].union_id !== filteredCustomers[index - 1].union_id ? (
                  <ListSubheader className={classes.stickyHeader}>
                    {customer.union_name}
                  </ListSubheader>
                ) : null}
                <CustomerListItem
                  name={customer.name}
                  customerID={customer.customer_id}
                  userCount={customer.user_count}
                  goLiveDate={customer.go_live_date}
                  active={activeIndex === index + page * rowsPerPage}
                  chooseCustomer={chooseCustomer}
                />
              </ul>
            </li>
          ))}
      </List>
      {customers.length > 10 && (
        <EnhancedTablePagination
          arrayLength={filteredCustomers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          entriesPerPageText={t('Organization_customer_select_pagination')}
        />
      )}
    </>
  )
}

export default CustomerList
